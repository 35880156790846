<template>
  <div class="online">
    <Crumbs :item="title"></Crumbs>
    <main>
      <img
          class="online-banner"
          src="@/static/images/secondaryMenu/productsServices/service_banner@2x.png"
          alt="banner"
      />
      <div class="leaveMessage">
        <p>在线留言</p>
        <p>
          如果您我对我们的产品和服务有任何疑问和建议，您可以给我们留言，<br/>
          以便我们能够进一步提供更好的服务。
        </p>
      </div>
      <div class="formData">
        <el-form label-position="top" :model="formData" ref="formRef">
          <el-row>
            <el-col :span="!isIos?8:20" :offset="!isIos?4:2">
              <el-form-item label="您想针对哪方面为我们留言：">
                <el-select v-model="formData.type" style="width: 100%">
                  <el-option label="对我们的产品和服务有任何疑问和建议" value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <!--          <template v-if="formData.type">-->
          <!-- <el-row>
            <el-col :span="16" :offset="4">
              <div class="formData_title">请您留言</div>
            </el-col>
          </el-row> -->
          <!-- <el-row>
            <el-col :span="10" :offset="4">
              <el-form-item label="标题">
                <el-input
                  v-model="formData.title"
                  placeholder="请输入标题内容"
                  style="width: 100%"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row> -->
          <el-row>
            <el-col :span="!isIos?16:20" :offset="!isIos?4:2">
              <div class="formData_title">
                请留下您的个人信息以及联系方式
                <span>(电子邮件或者电话至少填写一项)</span>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col :span="!isIos?8:20" :offset="!isIos?4:2">
              <el-form-item label="所在省/直辖市：">
                <el-select v-model="formData.province" style="width: 100%">
                  <el-option v-for="(item,index) in province" :key="index" :label="item" :value="item"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col :span="!isIos?8:10" :offset="!isIos?4:2">
              <el-form-item label="您的姓名">
                <el-input
                    v-model="formData.name"
                    placeholder="请输入您的姓名"
                    style="width: 100%"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="!isIos?8:10" >
              <el-form-item label="您的称呼">
                <el-radio-group
                    v-model="formData.sex"
                    class="sex-radio"
                    style="width: 100%"
                >
                  <el-radio-button label="0">男士</el-radio-button>
                  <el-radio-button label="1">女士</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="6">
               <el-form-item label="所在省/直辖市">
                <el-select v-model="formData.province" style="width: 100%">
                  <el-option
                    v-for="(val, key) in province"
                    :key="key"
                    :label="val"
                    :value="val"
                  />
                </el-select>
              </el-form-item> 
              <el-form-item label="座机">
                <el-input
                    placeholder="请输入座机号"
                    v-model="formData.phone"
                ></el-input>
              </el-form-item>
            </el-col> -->
          </el-row>
          <el-row :gutter="40">
            <el-col :span="!isIos ? 8 : 10" :offset="!isIos?4:2">
              <el-form-item label="座机">
                <el-input
                  placeholder="请输入座机号"
                  v-model="formData.phone"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col :span="!isIos?8:10" :offset="!isIos?4:2">
              <el-form-item label="电子邮件">
                <el-input
                    v-model="formData.email"
                    placeholder="请输入电子邮件"
                    style="width: 100%"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="!isIos?8:10" >
              <el-form-item label="手机">
                <el-input
                    v-model="formData.mobile"
                    maxlength="11"
                    minlength="11"
                    placeholder="输入手机号"
                    style="width: 100%"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col :span="!isIos?16:20" :offset="!isIos?4:2">
              <el-form-item label="留言内容">
                <el-input
                    type="textarea"
                    :rows="7"
                    v-model="formData.content"
                    placeholder="请输入留言内容"
                    style="width: 100%"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col :span="!isIos?4:10" :offset="!isIos?10:8">
              <div class="submit-btn" @click="submit">确认提交</div>
            </el-col>
          </el-row>
          <!--          </template>-->
        </el-form>
      </div>
    </main>
    <Vcode :imgs="img" :show="isShow" @success="onSauccess" @close="onClose"/>
  </div>
</template>

<script>
import Vcode from "vue-puzzle-vcode";
import Crumbs from "@/components/crumbs.vue";
import {province} from "@/constant/index";

import {onlineMessage} from "@/api/service.js";

export default {
  name: "online",
  data() {
    return {
      province,
      title: [
        {
          title: "客户服务",
          link: "",
        },
        {
          title: "在线自助服务",
          link: "",
        },
        {
          title: "在线留言",
          link: "",
        },
      ],
      formData: {
        type: "2",
        // title: "",
        content: "",
        name: "",
        sex: "0",
        province: "北京",
        email: "",
        userno: "",
        mobile: "",
        phone: "",
      },
      img: [require("@/static/images/secondaryMenu/productsServices/dypp.png"), require("@/static/images/secondaryMenu/productsServices/wlzx1.jpeg"), require("@/static/images/secondaryMenu/productsServices/bxscjlq.jpeg")],
      isShow: false,
      isIos: false,
    };
  },
  components: {Crumbs, Vcode},
  created() {
   
  },
  mounted() {
    this.isIos = window.navigator.userAgent.match(
        /(iPhone|iPod|ios|iPad)/i
    );
    console.log(!this.isIos);
  },
  methods: {
    submit() {
      if (!this.formData.name) return this.$message.warning("请填写您的姓名");
      if (!this.formData.mobile && !this.formData.phone&&!this.formData.email) {
        return this.$message.warning("为了便于与您联系，还请留下您的联系方式");
      }
      if (this.formData.mobile) {
        const reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
        if (!reg.test(this.formData.mobile)) {
          return this.$message.warning("请填写正确的手机号");
        }
      }
      if (!this.formData.content) {
        return this.$message.warning("请输入留言内容");
      }
      this.isShow = true;
    },
    async onSauccess() {
      try {
        const [timeStamp, sign] = this.$getSign();
        const res = await onlineMessage({
          timeStamp,
          sign,
          fktype: this.formData.type,
          username: this.formData.name,
          usersex: this.formData.sex,
          userphone: this.formData.mobile,
          telphone: this.formData.phone,
          useremail: this.formData.email,
          userno: this.formData.userno,//合同编号
          usercontent: this.formData.content,
          userarea: this.formData.province,
          userdate: new Date(),
        });
        console.log(res);
        if (res.data.code == 200) {
          this.$message.success("您的反馈提交成功");
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          this.formData = this.$options.data().formData;
        }
      } catch (error) {
        this.$message.error(error);
        console.log(error);
      } finally {
        this.isShow = false;
      }
    },
    onClose() {
      this.isShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>

.online {
  width: 100%;
  main {
    width: 80%;
    margin: auto;

    .online-banner {
      width: 100%;
      margin: 5rem 0 0;
    }

    .leaveMessage {
      text-align: center;

      p {
        font-family: Heiti SC;
        color: #000000;

        &:first-child {
          font-size: 3.6rem;
          font-weight: 500;
          margin: 8.5rem 0 4rem 0;
        }

        &:last-child {
          font-size: 2rem;
          font-weight: 300;
          margin-bottom: 10rem;
        }
      }
    }

    .formData_title {
      background: #003781;
      font-size: 2rem;
      font-family: Heiti SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 5rem;
      padding-left: 2.5rem;
      margin: 3rem 0 ;

      span {
        font-size: 1.4rem;
      }
    }

    .sex-radio {
      ::v-deep label {
        width: 50%;
text-align: center;
        .el-radio-button__inner {
          width: 100%;
        }
      }

      ::v-deep label.is-active .el-radio-button__inner {
        background-color: rgba(0, 55, 129, 1);
      }
    }

    .submit-btn {
      height: 7rem;
      background: #003781;
      font-size: 2.4rem;
      font-family: Heiti SC;
      font-weight: 300;
      color: #ffffff;
      line-height: 4.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 4rem 0 13rem 0;
      cursor: pointer;
    }
  }
}
</style>
